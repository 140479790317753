import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Popover } from '@headlessui/react';
import RangeInput from '../components/RangeInput';
import { getFinalImageColor } from '../../utils/parse';
import CaretDownIcon from '../../assets/icons/caret-down.png';
import FilterAltIcon from '../../assets/icons/filter-alt.png';

const DEFAULT_ALL_ITEMS = { id: 0, name: 'Todas', products_count: 0 };

const AsideFilters = forwardRef(({ filters, onChange }, ref) => {
  const productBrands = useSelector(state => state.productBrands);
  const productCategories = useSelector(state => state.productCategories);
  const filterRef = useRef();

  const toggleItem = (id) => {
    const element = document.getElementById(id);
    element.classList.toggle('show');
  }

  const selectBrand = (brand) => {
    let brands = [brand];

    if (brand === DEFAULT_ALL_ITEMS.id)
      brands = [];

    onChange({ ...filters, brands, resetPrice: true });
  }

  const selectCategory = (category) => {
    let categories = [category];

    if (category === DEFAULT_ALL_ITEMS.id)
      categories = [];

    onChange({ ...filters, categories, resetPrice: true });
  }

  useImperativeHandle(ref, () => ({
    toggle() {
      toggleFilters();
    },
    selfClick(e) {
      selfClick(e);
    },
    parentClick(e) {
      parentClick(e);
    },
    categoryOptions() {
      return <CategoryOptions />;
    },
  }));

  const toggleFilters = () => {
    filterRef.current.classList.toggle('show');
  }

  const selfClick = (e) => {
    e.target.click();
  }

  const parentClick = (e) => {
    if (String(e.target.parentElement.parentElement.firstChild.id).startsWith('headlessui-popover-button-'))
      e.target.parentElement.parentElement.firstChild.click();
  }

  const CategoryOptions = () => (
    <div className="collapsable">
      {productCategories.map(x => {
        const isSelected = (x.id === DEFAULT_ALL_ITEMS.id && !filters.categories.length) || filters.categories.includes(x.id);
        const selectedClass = isSelected ? ' selected' : '';

        return (
          <button
            key={x.id}
            className={`radio${selectedClass}`}
            onClick={() => selectCategory(x.id)}
          >
            <div className="with-suffix">
              <span>{ x.name }</span>
              <span>{ x.products_count || 0 }</span>
            </div>
          </button>
        )
      })}
    </div>
  )

  const BrandOptions = () => (
    <div className="collapsable">
      {productBrands.map(x => {
        const isSelected = (x.id === DEFAULT_ALL_ITEMS.id && !filters.brands.length) || filters.brands.includes(x.id);
        const selectedClass = isSelected ? ' selected' : '';

        return (
          <button
            key={x.id}
            className={`radio${selectedClass}`}
            onClick={() => selectBrand(x.id)}
          >
            <div className="with-suffix">
              <span>{ x.name }</span>
              <span>{ x.products_count }</span>
            </div>
          </button>
        )
      })}
    </div>
  )

  const ProductOptions = () => (
    <div className="collapsable">
      <div className="flex items-center cursor-pointer p-2 text-base font-normal text-gray-500">
        <div className="flex items-center">
          <input
            id="avoidOutOfStock"
            checked={!filters.avoidOutOfStock}
            type="checkbox"
            className="w-4 h-4 bg-gray-100 rounded border-gray-300"
            onChange={(e) => onChange({ ...filters, avoidOutOfStock: !e.target.checked, resetPrice: true })}
          />
          <label htmlFor="avoidOutOfStock" className="ml-2 text-gray-600 text-[11px] leading-3 sm:text-xs">
            Mostrar productos sin stock
          </label>
        </div>
      </div>
    </div>
  )

  const PriceOptions = () => (
    <div className="collapsable">
      <RangeInput
        min={0}
        max={filters.upperPrice}
        uppestPrice={filters.uppestPrice}
        onChange={range => onChange({ ...filters, ...range })}
      />
    </div>
  )

  return (
    <aside className="filters" ref={filterRef}>
      <button className="close md:hidden absolute" onClick={toggleFilters}>
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"/></svg>
      </button>

      <div className="hidden md:flex gap-8">
        <Popover className="relative md:show">
          <Popover.Button onMouseEnter={selfClick} onMouseLeave={selfClick}>
            <div className="header-filter-btn">
              <span>Marcas</span>
              <img
                src={CaretDownIcon}
                alt="Caret"
                className="w-6 h-6"
                style={{ ...getFinalImageColor('--bg-header','#FFFFFF') }}
                onClick={() => {}}
              />
            </div>
          </Popover.Button>
          <Popover.Panel className="header-filter-pop" onMouseEnter={parentClick} onMouseLeave={parentClick}>
            <BrandOptions />
          </Popover.Panel>
        </Popover>

        <Popover className="relative md:show">
          <Popover.Button onMouseEnter={selfClick} onMouseLeave={selfClick}>
            <div className="header-filter-btn">
              <span>Filtros</span>
              <img
                src={FilterAltIcon}
                alt="Filter"
                className="w-5 h-5"
                style={{ ...getFinalImageColor('--bg-header','#FFFFFF') }}
                onClick={() => {}}
              />
            </div>
          </Popover.Button>
          <Popover.Panel className="header-filter-pop" onMouseEnter={parentClick} onMouseLeave={parentClick}>
            <h1>Productos</h1>
            <ProductOptions />
            <div className="border-b border-gray-200 w-full" />
            <h1>Precio</h1>
            <PriceOptions />
          </Popover.Panel>
        </Popover>
      </div>

      <div className="make-sticky md:hidden">
        <div id="category-filter" className="md:show">
          <button className="collapsable-trigger" onClick={() => toggleItem('category-filter')}>
            <h1>Categorías</h1>
            <ChevronIcon />
          </button>

          <CategoryOptions />
        </div>

        <div id="brand-filter" className="md:show">
          <button className="collapsable-trigger" onClick={() => toggleItem('brand-filter')}>
            <h1>Marcas</h1>
            <ChevronIcon />
          </button>

          <BrandOptions />
        </div>

        <div id="product-filter" className="md:show">
          <button className="collapsable-trigger" onClick={() => toggleItem('product-filter')}>
            <h1>Productos</h1>
            <ChevronIcon />
          </button>

          <ProductOptions />
        </div>

        <div id="price-filter" className="md:show">
          <button className="collapsable-trigger" onClick={() => toggleItem('price-filter')}>
            <h1>Precio</h1>
            <ChevronIcon />
          </button>

          <PriceOptions />
        </div>
      </div>
    </aside>
  )
})

const ChevronIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
  </svg>
)

export default AsideFilters