import { useEffect, useRef, useState } from 'react';
import { formatAmount } from '../../utils/parse';

const RangeInput = ({ min, max, uppestPrice, onChange }) => {

  const upperSlider = useRef();
  const lowerSlider = useRef();
  const debouncer = useRef();
  const [range, setRange] = useState({
    lowerValue: min,
    upperValue: max,
  });

  const onChangeSlider = (e) => {
    const currentUpperValue = parseFloat(upperSlider.current.value);
    const currentLowerValue = parseFloat(lowerSlider.current.value);
    let newUpperValue = currentUpperValue;
    let newLowerValue = currentLowerValue;

    if (e.target.id === 'price-lower') {
      if (currentLowerValue > currentUpperValue) {
        newLowerValue = currentUpperValue;
      }
      if (currentLowerValue < min) {
        newLowerValue = min;
      }

    } else {
      if (currentUpperValue < currentLowerValue) {
        newUpperValue = currentLowerValue;
      }
      if (currentUpperValue > max) {
        newUpperValue = max;
      }
    }

    if (!!debouncer.current) clearTimeout(debouncer.current);

    debouncer.current = setTimeout(() => {
      onChange({ lowerPrice: newLowerValue, upperPrice: newUpperValue });
    }, 1500);

    setRange({ lowerValue: newLowerValue, upperValue: newUpperValue });
  };

  const reset = () => {
    setRange({ lowerValue: min, upperValue: uppestPrice });
    onChange({ lowerPrice: min, upperPrice: uppestPrice });
  }

  useEffect(() => {
    setRange(s => ({ ...s, upperValue: max }));
  }, [max]);

  return (
    <div className="multi-range">
      <div className="text-center font-bold text-sm">
        {formatAmount(range.lowerValue)} - {formatAmount(range.upperValue)}+
      </div>

      <div className="range-input">
        <input
          ref={lowerSlider}
          type="range"
          id="price-lower"
          value={range.lowerValue}
          min={min}
          max={max}
          onChange={onChangeSlider}
        />
        <input
          ref={upperSlider}
          type="range"
          id="price-upper"
          value={range.upperValue}
          min={min}
          max={max}
          onChange={onChangeSlider}
        />
      </div>

      <div className="amount-range">
        <span>{ formatAmount(min) }</span>
        <span>{ formatAmount(max / 2) }</span>
        <span>{ formatAmount(max) }+</span>
      </div>

      {!(range.lowerValue === min && range.upperValue === uppestPrice) && (
        <p className="reset" onClick={reset}>
          Reiniciar rango de precios
        </p>
      )}
    </div>
  )
}

export default RangeInput