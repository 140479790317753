const fillCart = (products) => {
  return { type: 'FILL_CART', payload: products };
};

const addToCart = (product) => {
  return { type: 'ADD_TO_CART', payload: product };
};

const removeFromCart = (product) => {
  return { type: 'REMOVE_FROM_CART', payload: product };
};

const updateCartProduct = (product) => {
  return { type: 'UPDATE_CART_PRODUCT', payload: product };
};

const clearCart = () => {
  return { type: 'CLEAR_CART' };
};

export {
  fillCart,
  addToCart,
  removeFromCart,
  updateCartProduct,
  clearCart
}