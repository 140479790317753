const initialState = {
  currency_symbol: '$',
  currency_format: 'en-US',
};

const currency = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_CURRENCY':
      return payload;

    default:
      return state;
  }
}

export default currency;