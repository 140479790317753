import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'react-hot-toast';
import Router from './router';
import { store, persistor } from './store';
import { setGlobalColors } from './utils/parse';

const App = () => {
  setGlobalColors();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <Router />
          <Toaster position='bottom-center' />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App;
