import api from '../utils/api';
import { toParams } from '../utils/parse';

export const getSession = (params) => {
  return api.post('store/cart', params);
}

export const getCategories = (params) => {
  const filter = !!params ? toParams(params) : '';
  return api.post(`store/categories${filter}`, params);
}

export const getBrands = (params) => {
  const filter = !!params ? toParams(params) : '';
  return api.post(`store/brands${filter}`, params);
}

export const getDispatchTypes = (params) => {
  return api.post('admin/public-store/despachos', params);
}

export const getColors = (params = {}) => {
  return api.post('store/products/colors', params);
}

export const getProducts = (params, pagination) => {
  const filter = !!pagination ? toParams(pagination) : '';
  return api.post(`store/products${filter}`, params);
}

export const confirm = (params) => {
  return api.post('store/cart/confirm', params);
}

export const getAdminId = (params) => {
  return api.post('admin/public-store/get-admin-id', params);
}

export const save = (params) => {
  return api.post('store/cart/save', params);
}

export const checkPlanUser = (params) => {
  return api.post('auth/check-planuser', params);
}