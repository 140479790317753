const fillSizes = (sizes) => {
  return { type: 'FILL_SIZES', payload: sizes };
};

const fillColors = (colors) => {
  return { type: 'FILL_COLORS', payload: colors };
};

export {
  fillSizes,
  fillColors
}