import { combineReducers } from 'redux';
import cart from './cart.reducer';
import colors from './colors.reducer';
import sizes from './sizes.reducer';
import dispatchTypes from './dispatchTypes.reducer';
import productBrands from './productBrands.reducer';
import productCategories from './productCategories.reducer';
import conversion from './conversion.reducer';
import currency from './currency.reducer';

export default combineReducers({
  cart,
  dispatchTypes,
  productCategories,
  productBrands,
  sizes,
  colors,
  conversion,
  currency,
});