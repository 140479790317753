const initialState = [];

const productBrands = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILL_PRODUCT_BRANDS':
      return payload;

    default:
      return state;
  }
}

export default productBrands;