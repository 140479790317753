/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Popover, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import * as actions from '../store/actions';
import { StoreService } from '../services';
import { copy, formatAmount, getFinalImageColor, toAmount, setGlobalColors } from '../utils/parse';
import Socket from '../utils/socket';
import SocketEvents from '../utils/socket-events';
import Checkout from './Checkout-Modal';
import FacebookIcon from '../assets/icons/facebook.png';
import InstagramIcon from '../assets/icons/instagram.png';
import TwitterXIcon from '../assets/icons/twitter-x.png';
import WhatsAppIcon from '../assets/icons/whatsapp.png';
import CaretDownIcon from '../assets/icons/caret-down.png';
import FilterIcon from '../assets/icons/filter.png';
import CategoryIcon from '../assets/icons/grid.png';
import ShoppingCartIcon from '../assets/icons/shopping-cart.png';
import AsideFilters from './components/AsideFilters';

let debounce = null;

const VIEW_MODE = {
  LIST: 1,
  GRID: 2
};

const SORT = {
  PRICE_ASC: 1,
  PRICE_DESC: 2,
  RECENT: 3,
  ALFA: 4
};

const SELL_TYPES = {
  NORMAL: 1,
  SIZE_COLORS: 2
}

const DEFAULT_CATEGORY = { id: 0, name: 'Todas', products_count: 0 };
const DEFAULT_BRAND = { id: 0, name: 'Todas', products_count: 0 };

let SORT_OPTIONS = [
  { value: SORT.ALFA, label: 'Alfabetico' },
  { value: SORT.RECENT, label: 'Más reciente' },
];

const allPrices = 1000000;

const initialFilters = {
  admin_id: null,
  lowerPrice: 0,
  upperPrice: allPrices,
  uppestPrice: 0,
  resetPrice: true,
  canFetch: false,
  brands: [],
  search: '',
  sort: SORT.ALFA,
  categories: [],
  avoidOutOfStock: true,
};

const SearchProducts = () => {
  const _cart = useSelector(state => state.cart);
  const colors = useSelector(state => state.colors);
  const sizes = useSelector(state => state.sizes);
  const dispatch = useDispatch();
  const { url } = useParams();

  const filtersRef = useRef();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [show_prices, setShowPrices] = useState(false);
  const [showPreview, setShowPreview] = useState({ show: false, product: null });
  const [showProduct, setShowProduct] = useState({ show: false, product: null, isSelected: false });

  const [filters, setFilters] = useState(copy(initialFilters));
  const cart = _cart.filter(x => x.admin_id === filters.admin_id);

  const [showSort, setShowSort] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE.GRID);

  const [pagination, setPagination] = useState({ current_page: 1, last_page: 1, per_page: 30 });
  const [products, setProducts] = useState([]);
  const [interestingProducts, setInterestingProducts] = useState([]);
  const [shop, setShop] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await StoreService.getAdminId({ url });
        if (!res.data.admin_id) {
          toast.error('Lo sentimos, no se pudo encontrar la tienda');
          return;
        }
        const checkPlanRes = await StoreService.checkPlanUser({ user_id: res.data.admin_id, public_store: true });
        if (!checkPlanRes.data.result) {
          toast.error(checkPlanRes.data?.error);
          return;
        }
        await setFilters(s => ({ ...s, canFetch: true, admin_id: res.data.admin_id }));
        getSessionProducts();
        getCategories(res.data.admin_id);
        getDispatchTypes(res.data.admin_id);
        setIsLoading(true);
        getColors();
        getInterestingProducts(res.data.admin_id);
      } catch (error) {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      }
    };

    const query = new URLSearchParams(window.location.search);
    if (query.get('store_session')) {
      Socket.on(SocketEvents.PUBLIC_STORE.PREVIEW.BANNER, ({ banner, store_session }) => {
        if (store_session !== query.get('store_session')) return;
        setShop(s => ({ ...s, banner }));
      });
      Socket.on(SocketEvents.PUBLIC_STORE.PREVIEW.COLORS, ({ header_bg, footer_bg, store_session }) => {
        if (store_session !== query.get('store_session')) return;
        setGlobalColors(header_bg, footer_bg);
      });
    }

    fetchData();
  }, [url]);

  useEffect(() => {
    if (!filters.admin_id) return;
    if (filters.upperPrice !== initialFilters.upperPrice) {
      initialFilters.upperPrice = filters.upperPrice;
      return setFilters(s => ({ ...s, execFilter: true }));
    }
    if (!filters.canFetch) return;
    getBrands(filters.admin_id);
    getProducts();
  }, [
    filters.admin_id,
    filters.avoidOutOfStock,
    filters.brands,
    filters.categories,
    filters.lowerPrice,
    filters.search,
    filters.sort,
    filters.upperPrice,
  ]);

  const minPrice = (product) => {
    if (Number(product.type) === SELL_TYPES.NORMAL) {
      if (product.default_price == 1) {
        return product.sale_price;
      }
      else {
        const priceObj = product.prices.find(price => price.default_price === 1);
        if (priceObj) {
          return priceObj.price;
        }
        else {
          return product.sale_price;
        }
      }
    }

    let prices = product.size_colors.filter(i => i.stock > 0).map(i => {
      const priceObj = i.prices.find(price => price.default_price === 1);
      if (priceObj) {
        return priceObj.price;
      }
      else {
        return i.sale_price;
      }
    });
    if (prices.length == 0) {
      prices = [0];
    }
    return Math.min(...prices);
  }

  const getLowestPriceSizeColor = (product) => {
    if (Number(product.type) === SELL_TYPES.NORMAL) return {};

    const lowestPrice = minPrice(product);
    const sizeColor = product.size_colors.find(x => x.sale_price === lowestPrice);
    return { size_id: sizeColor?.size_id, color_id: sizeColor?.color_id };
  }

  const getSessionProducts = async () => {
    if (!cart.length) return;
    const product_ids = cart.map(x => x.id);

    StoreService.getSession({ product_ids })
      .then(({ data }) => {
        const resume = data.products.map(bdProd => {
          const cart_item = cart.find(cartProd => cartProd.id === bdProd.id);
          const quantity = cart_item?.quantity || 1;
          const size_id = cart_item?.size_id || '';
          const color_id = cart_item?.color_id || '';
          return { ...bdProd, quantity, color_id, size_id };
        });
        dispatch(actions.fillCart(resume));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getCategories = async (admin_id) => {
    StoreService.getCategories({ admin_id, stock_on: filters.avoidOutOfStock || null })
      .then(res => {
        DEFAULT_CATEGORY.products_count = res.data.categories.data.reduce((acc, curr) => acc + curr.products_count, 0);
        dispatch(actions.fillProductCategories([
          DEFAULT_CATEGORY,
          ...res.data.categories.data
        ]));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getBrands = async (admin_id) => {
    StoreService.getBrands({ admin_id, stock_on: filters.avoidOutOfStock || 0 })
      .then(res => {
        DEFAULT_BRAND.products_count = res.data.brands.data.reduce((acc, curr) => acc + curr.products_count, 0);
        dispatch(actions.fillProductBrands([
          DEFAULT_BRAND,
          ...res.data.brands.data,
        ]));
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getDispatchTypes = async (admin_id) => {
    StoreService.getDispatchTypes({ admin_id })
      .then(res => {
        const options = res.data.despachos
          .filter(x => Boolean(x.status))
          .map(x => ({ value: x.id, label: x.name }));

        dispatch(actions.fillDispatchTypes(options));
        dispatch(actions.setConversion(res.data.conversion));
        dispatch(actions.setCurrency(res.data.shop.country));

        const banner = res.data.appearance?.banner ? (process.env.REACT_APP_STORAGE + (res.data.appearance?.banner || '')) : '';
        const header_bg = res.data.appearance?.header_bg;
        const footer_bg = res.data.appearance?.footer_bg;
        setGlobalColors(header_bg, footer_bg);

        setShop({ ...res.data.shop, banner });
        setShowPrices(res.data.show_prices);
        if (res.data.show_prices) {
          SORT_OPTIONS = [
            ...SORT_OPTIONS,
            {value: SORT.PRICE_DESC, label: 'Mayor precio'},
            {value: SORT.PRICE_ASC, label: 'Menor precio'},
          ];
        }
        setIsFirstLoad(false);
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      });
  }

  const getColors = async (admin_id) => {
    StoreService.getColors()
      .then(res => {
        dispatch(actions.fillSizes(res.data.sizes));
        dispatch(actions.fillColors(res.data.colors));
      })
      .catch(error => {
        console.log(error)
      });
  }

  const getProducts = async (page = 1) => {
    if (!filters.canFetch && page == 1) return;
    if (!isLoading) setIsLoading(true);
    // if (page === 1 && !!products.length) setProducts([]);

    StoreService.getProducts({
      admin_id: filters.admin_id,
      search: filters.search,
      brands_filter: !!filters.brands.length ? filters.brands : null,
      category_filter: !!filters.categories.length ? filters.categories : null,
      sort_by: filters.sort || null,
      stock_on: filters.avoidOutOfStock || null,
      lower_price: filters.lowerPrice || 0,
      upper_price: filters.resetPrice ? allPrices : filters.upperPrice,
    }, { page })
      .then(res => {
        const prods = res.data.products.data.map(x => ({
          ...x,
          quantity: 1,
          minPrice: minPrice(x),
          ...getLowestPriceSizeColor(x),
          size_colors: filters.avoidOutOfStock
            ? x.size_colors.filter(sc => sc.stock > 0)
            : x.size_colors,
        }));

        if (filters.resetPrice) {
          setFilters(s => ({
            ...s,
            upperPrice: res.data.price_max || 0,
            uppestPrice: res.data.price_max || 0,
            resetPrice: false,
            canFetch: false,
          }));
        } else {
          setFilters(s => ({ ...s, canFetch: false }));
        }

        page > 1
          ? setProducts([...products, ...prods])
          : setProducts(prods);

        setPagination({
          current_page: res.data.products.current_page,
          last_page: res.data.products.last_page,
          per_page: res.data.products.per_page,
        });
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      })
      .finally(() => setIsLoading(false));
  }

  const getInterestingProducts = async (adminId, page = 1) => {
    // TODO: Cambiar endpoint
    StoreService.getProducts({
      admin_id: adminId,
      search: filters.search,
      category_filter: !!filters.categories.length ? filters.categories : null,
      sort_by: filters.sort || null,
      stock_on: filters.avoidOutOfStock || null,
    }, { page })
      .then(res => {
        const prods = res.data.products.data.map(x => ({
          ...x,
          quantity: 1,
          minPrice: minPrice(x),
          ...getLowestPriceSizeColor(x),
          size_colors: filters.avoidOutOfStock
            ? x.size_colors.filter(sc => sc.stock > 0)
            : x.size_colors,
        }));

        setInterestingProducts(prods);
      })
      .catch(error => {
        console.log(error);
        toast.error('Ocurrió un error al obtener los datos');
      })
      .finally(() => setIsLoading(false));
  }

  const addToCart = (product,index) => {
    if (product.type == SELL_TYPES.SIZE_COLORS) {
      if (!product.color_id && !product.size_id) {
        toast.error('Debe seleccionar el color/talla del producto');
        return false;
      }

      if (!product.size_colors?.find(i => i.size_id == product.size_id && i.color_id == product.color_id)) {
        toast.error('Lo sentimos, el producto con estas especificaciones no se encuentra en inventario');
        return false;
      }
    }

    if (product.type == SELL_TYPES.NORMAL) {
      const isSelected = Boolean(cart.find(x => x.id === product.id));

      isSelected
        ? dispatch(actions.removeFromCart(product))
        : dispatch(actions.addToCart(product));
    }
    else {
      const isSelected = Boolean(cart.find(x => x.id === product.id && x.color_id == product.color_id && x.size_id == product.size_id));

      if (!isSelected) {
        dispatch(actions.addToCart(product));
      }
    }

    if (Number.isInteger(index)) {
      let _products = [...JSON.parse(JSON.stringify(products))];
      _products[index].color_id = '';
      _products[index].size_id = '';
      setProducts(_products);
    }
  }

  const openLink = (url, isWhatsapp = false) => {
    if (isWhatsapp) {
      const link = 'https://wa.me/';
      const phone = Number(String(url).trim());
      if (isNaN(phone)) return;
      url = `${link}+${String(phone).startsWith('58') ? '':'58'}${phone}`;
    }
    window.open(url, '_blank');
  }

  return (
    <div id="search-products">
      {(isFirstLoad || isLoading) && (
        <div className="fixed z-20 w-screen h-screen left-0 right-0 flex justify-center items-center self-center" style={{ backgroundColor: isFirstLoad ? '#FFFFFF' : '#00000066' }}>
          <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-300 animate-spin fill-[#006DDB]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Cargando...</span>
          </div>
        </div>
      )}

      {showPreview.show && (
        <div
          className="bg-[#00000088] fixed z-30 w-full h-full flex justify-center items-center"
          onClick={() => setShowPreview({ show: false, product: null })}
        >
          <div
            className="absolute top-4 right-10 cursor-pointer z-10"
            onClick={() => setShowPreview({ show: false, product: null })}
          >
            <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path style={{ fill: 'white' }} d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F"/>
            </svg>
          </div>
          <img
            className="max-w-[36rem] max-h-[36rem] p-2"
            src={process.env.REACT_APP_STORAGE + showPreview.product?.photo?.file}
            alt="Foto del producto"
          />
        </div>
      )}

      {showCart && (
        <Checkout
          admin_id={filters.admin_id}
          products={products}
          sizes={sizes}
          colors={colors}
          show_prices={show_prices}
          onClose={() => setShowCart(false)}
        />
      )}

      {/* SIDEBAR - ORDENAR POR */}
      <SidebarOrder
        show={showSort}
        selected={filters.sort}
        onSelect={sort => setFilters(state => ({ ...state, canFetch: true, sort }))}
        onClose={() => setShowSort(false)}
      />

      {showProduct.show && (
        <ProductDetails
          showProduct={showProduct}
          setShowProduct={setShowProduct}
          setShowPreview={setShowPreview}
          addToCart={addToCart}
          showPrices={show_prices}
          interestingProducts={interestingProducts.slice(0,8)}
        />
      )}

      <section className="relative w-full flex-1">
        <header className="w-full bg-[--bg-header]">
          <div>
            <div className="flex main">
              <div className="header-shop">
                {shop && (
                  <img
                    className="header-img"
                    src={ process.env.REACT_APP_STORAGE + shop?.logo }
                    alt={`${shop?.name} Logo`}
                    onClick={() => window.location.reload()}
                  />
                )}
              </div>

              <div className="flex flex-col flex-1">
                <div className="flex flex-row flex-1 items-center lg:gap-12 border-b border-b-[--bg-header-border]">
                  <div className="filters">
                    <Popover className="relative md:show">
                      <Popover.Button
                        onMouseEnter={filtersRef.current?.selfClick}
                        onMouseLeave={filtersRef.current?.selfClick}
                      >
                        <div className="header-filter-btn category-filter">
                          <img
                            src={CategoryIcon}
                            alt="Categorías"
                            style={{ ...getFinalImageColor('--bg-header','#FFFFFF') }}
                            onClick={() => {}}
                          />
                          <span className="md:text-[20px] md:font-bold">Categorías</span>
                          <img
                            src={CaretDownIcon}
                            alt="Caret"
                            style={{ ...getFinalImageColor('--bg-header','#FFFFFF') }}
                            onClick={() => {}}
                          />
                        </div>
                      </Popover.Button>
                      <Popover.Panel
                        className="header-filter-pop"
                        onMouseEnter={filtersRef.current?.parentClick}
                        onMouseLeave={filtersRef.current?.parentClick}
                      >
                        { filtersRef.current?.categoryOptions() }
                      </Popover.Panel>
                    </Popover>
                  </div>

                  {/* BÚSQUEDA */}
                  <div className="my-4 lg:my-4 w-full flex flex-col flex-1">
                    <div>
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Escribe para buscar en la tienda"
                          className="w-full h-9 py-2 pl-4 pr-4 text-gray-700 rounded-full focus:outline-none text-xs sm:text-sm focus:border-yellow-300 focus:ring-yellow-300 bg-gray-100"
                          onChange={v => {
                            if (debounce) clearTimeout(debounce);
                            debounce = setTimeout(() => {
                              setFilters(s => ({ ...s, canFetch: true, search: v.target.value }));
                            }, 1000);
                          }}
                        />

                        <span className="absolute right-0 top-1 mr-1 flex justify-center items-center w-7 h-7 bg-black rounded-full">
                          <svg className="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                            <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="main header-filters">
                  <AsideFilters
                    ref={filtersRef}
                    filters={filters}
                    onChange={v => setFilters({ ...v, canFetch: true })}
                  />
                </div>
              </div>

              <CartResume
                cart={cart}
                show_prices={show_prices}
                onClick={() => setShowCart(true)}
              />
            </div>
          </div>
        </header>

        {!!shop?.banner && (
          <section className="banner-carousel">
            <div className="banner-carousel__item">
              <img
                className="absolute left-0 right-0 !w-full h-auto object-cover -z-10 blur-xl scale-125"
                src={shop?.banner}
                alt="Banner BG"
              />
              <img src={shop?.banner} alt="Banner" />
            </div>
          </section>
        )}

        <div className="flex main">
          <main className="flex-1">
            <div className="flex flex-row justify-end items-center mx-2 my-3 h-12">
              <div className="inline-flex items-center gap-2 md:gap-4">
                <div className="md:hidden">
                  <button
                    onClick={() => filtersRef.current.toggle()}
                    className="flex items-center cursor-pointer h-8 px-2 tracking-wide text-gray-500 rounded-sm focus:outline-none border border-gray-500"
                  >
                    <img src={FilterIcon} className="w-4 h-4 mr-1" alt="Filtrar" />
                    <span className="text-[11px] whitespace-nowrap sm:text-xs text-gray-500 ">Filtrar</span>
                  </button>
                </div>

                <div>
                  <button
                    onClick={() => setShowSort(!showSort)}
                    className="flex items-center cursor-pointer h-8 px-2 tracking-wide text-gray-500 rounded-sm focus:outline-none border border-gray-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                    </svg>

                    <span className="text-[11px] whitespace-nowrap sm:text-xs text-gray-500">Ordenar</span>
                  </button>
                </div>

                <div className="flex items-center h-8 px-1 py-0.5 tracking-wide text-gray-500 rounded-sm focus:outline-none border border-gray-500">
                  <button
                    className={'p-1 rounded-sm cursor-pointer' + (viewMode === VIEW_MODE.GRID ? ' bg-gray-200' : '')}
                    onClick={() => setViewMode(VIEW_MODE.GRID)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                    </svg>
                  </button>

                  <button
                    onClick={() => setViewMode(VIEW_MODE.LIST)}
                    className={'p-1 rounded-sm cursor-pointer' + (viewMode === VIEW_MODE.LIST ? ' bg-gray-200' : '')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className={`'mx-2 ${viewMode === VIEW_MODE.GRID ? 'show-grid':''}`}>
              {products.map((product,index) => {
                const isSelected = Boolean(cart.find(x => x.id === product.id));

                return (
                  <Product
                    key={`product-${product.id}`}
                    product={product}
                    isSelected={isSelected}
                    addToCart={() => addToCart(product,index)}
                    show_prices={show_prices}
                    showProduct={() => setShowProduct({ show: true, product, isSelected })}
                    showPreview={() => setShowPreview({ show: true, product })}
                  />
                )
              })}

              {isLoading && (
                <div className="loader flex justify-center mt-4">
                  <div role="status">
                    <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin fill-[#006DDB]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                    </svg>
                    <span className="sr-only">Cargando...</span>
                  </div>
                </div>
              )}

              {(!isLoading && !products.length) && (
                <div className="no-items">
                  <span className="text-sm">No se encontraron productos</span>
                </div>
              )}
            </div>

            {(!isLoading && pagination.current_page < pagination.last_page) && (
              <div className="flex justify-center mt-4">
                <button
                  onClick={() => getProducts(pagination.current_page + 1)}
                  type="button"
                  className="focus:outline-none text-gray-900 bg-yellow-300 hover:bg-yellow-400 focus:ring-4 font-medium rounded-md text-sm px-5 py-1.5"
                >Cargar más</button>
              </div>
            )}
          </main>
        </div>
      </section>

      <footer>
        <div className="main">
          <p className="shop-name">{ shop?.name }</p>
          <div className="social-media">
            {!!shop?.facebook && (
              <img
                src={FacebookIcon}
                className="social-icon"
                alt="Facebook"
                title="Facebook"
                onClick={() => openLink(shop.facebook)}
              />
            )}
            {!!shop?.messenger && (
              <img
                src={InstagramIcon}
                className="social-icon"
                alt="Messenger"
                title="Messenger"
                onClick={() => openLink(shop.messenger)}
              />
            )}
            {!!shop?.instagram && (
              <img
                src={InstagramIcon}
                className="social-icon"
                alt="Instagram"
                title="Instagram"
                onClick={() => openLink(shop.instagram)}
              />
            )}
            {!!shop?.phone && (
              <img
                src={WhatsAppIcon}
                className="social-icon"
                alt="WhatsApp"
                title="WhatsApp"
                onClick={() => openLink(shop.phone, true)}
              />
            )}
            {!!shop?.twitter && (
              <img
                src={TwitterXIcon}
                className="social-icon"
                alt="Twitter (X)"
                title="Twitter (X)"
                onClick={() => openLink(shop.twitter)}
              />
            )}
          </div>
        </div>
        <p className="credits">
          &copy; { new Date().getFullYear() } Sysge24. Todos los Derechos Reservados
        </p>
      </footer>
    </div>
  )
}

const ProductDetails = ({ showProduct, setShowProduct, showPrices, addToCart, setShowPreview, interestingProducts }) => {
  const cart = useSelector(state => state.cart);
  const sizes = useSelector(state => state.sizes);
  const productCategories = useSelector(state => state.productCategories);
  const colors = useSelector(state => state.colors);
  const product = copy(showProduct.product);
  console.log('Product: ', product)
  const prodSizes = [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))];
  const prodColors = [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))];
  const isSelected = product.type == SELL_TYPES.SIZE_COLORS
    ? Boolean(cart.find(x => x.id === product.id && x.color_id == product.color_id && x.size_id == product.size_id))
    : Boolean(cart.find(x => x.id === product.id));

  const enoughStock = product.type === SELL_TYPES.SIZE_COLORS
    ? product.size_colors.some(x => x.stock > 0)
    : product.stock > 0;

  const size_color = product?.size_colors?.find((i) => {
    if (product.size_id && product.color_id) {
      return i.size_id == product.size_id && i.color_id == product.color_id;
    }
    else if (product.size_id) {
      return i.size_id == product.size_id && i.color_id == null;
    }
    else if (product.color_id) {
      return i.color_id == product.color_id && i.size_id == null;
    }
  });

  const showPreview = () => {
    setShowPreview({ show: true, product: product });
  }

  const handleSelectSize = (e) => {
    e.stopPropagation();
    product.size_id = Number(e.target.value);
    setShowProduct(s => ({ ...s, product: { ...s.product, size_id: Number(e.target.value) } }));
  }

  const handleSelectColor = (e, value) => {
    e.stopPropagation();
    product.color_id = Number(value);
    setShowProduct(s => ({ ...s, product: { ...s.product, color_id: Number(value) } }));
  }

 const minPrice = (product) => {
    if (Number(product.type) === SELL_TYPES.NORMAL) {
      if (product.default_price == 1) {
        return product.sale_price;
      }
      else {
        const priceObj = product.prices.find(price => price.default_price === 1);
        if (priceObj) {
          return priceObj.price;
        }
        else {
          return product.sale_price;
        }
      }
    }

    let prices = product.size_colors.filter(i => i.stock > 0).map(i => {
      const priceObj = i.prices.find(price => price.default_price === 1);
      if (priceObj) {
        return priceObj.price;
      }
      else {
        return i.sale_price;
      }
    });
    if (prices.length == 0) {
      prices = [0];
    }
    return Math.min(...prices);
  }

  // const Price = () => {
  //   if (showPrices && (product.type == SELL_TYPES.NORMAL || size_color != null)) return (
  //     <h3 className="product-price text-2xl font-bold leading-none">
  //       {formatAmount(product.type == SELL_TYPES.NORMAL ? product.sale_price : size_color?.sale_price)}
  //     </h3>
  //   )

  //   return <></>;
  // }

  const Price = () => {
    if (!showPrices) return <></>;

    let precio = 0
    if(Number(product.type) === SELL_TYPES.NORMAL){
      if(product.default_price == 1){
        precio = product.sale_price
      }
      else{
        // Busca el primer objeto en product.prices donde default_price es 1
        const priceObj = product.prices.find(price => price.default_price === 1);
        // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
        if (priceObj) {
          precio = priceObj.price;
        }
      }
    }
    else if (size_color?.sale_price) {
      // Busca el primer objeto en product.prices donde default_price es 1
      const priceObj = size_color?.prices.find(price => price.default_price === 1);
      // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
      if (priceObj) {
        precio = priceObj.price;
      }
      else {
        precio = size_color?.sale_price
      }
    }
    else {
      precio = minPrice(product);
    }

    return (
      <h3 className="product-price text-2xl font-bold leading-none">
        {/* {formatAmount(product.type == SELL_TYPES.NORMAL ? product.sale_price : size_color?.sale_price)} */}
        {formatAmount(precio)}
      </h3>
    )
  }

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-black bg-opacity-25" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <div className="product-detail-modal w-full max-w-xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
            <div className="text-lg font-medium leading-6 px-5 py-4 text-gray-900 flex flex-row items-center justify-end">
              <div className="cursor-pointer" onClick={() => setShowProduct(s => ({ ...s, show: false }))}>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </div>
            </div>

            <div className="px-2 pb-6">
              <div className="product-details">
                <div className="product-image w-60 h-60">
                  <img
                    className="object-cover text-xs opacity-100"
                    src={process.env.REACT_APP_STORAGE + product.photo?.file}
                    alt={product.name}
                    onClick={showPreview}
                  />
                </div>
                <div className="product-info">
                  <div>
                    <p className="text-lg lg:text-xl leading-5 lg:leading-6 pb-2">{ product?.name }</p>
                    <div className="flex flex-col gap-1">
                      <h3 className="product-category" title="Categoría">
                        { productCategories.find(x => x.id === product.category_id)?.name }
                      </h3>
                      <Price />
                    </div>

                    {
                      product.type == SELL_TYPES.SIZE_COLORS && (
                        <div className="product-size-color mt-4">
                          {
                            prodColors.length > 0 && (
                              <div style={{ display: 'flex' }}>
                                {
                                  prodColors.map((i) => {
                                    return (
                                      <div
                                        key={`color-${product.id}${i}${new Date().getMilliseconds()}`}
                                        onClick={ (e) => handleSelectColor(e, i) }
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          borderRadius: '10px',
                                          marginRight: '10px',
                                          marginBottom: '10px',
                                          cursor: 'pointer',
                                          backgroundColor: colors?.find(item => item.id == i)?.hex,
                                          borderWidth: 5,
                                          borderColor: product.color_id == i ? '#00000066' : 'transparent',
                                          boxShadow: '0px 1px 5px -2px #000000',
                                        }}></div>
                                    )
                                  })
                                }
                              </div>
                            )
                          }

                          {
                            prodSizes.length > 0 && (
                              <div className="flex flex-row justify-start gap-4">
                                <label className="font-medium">Talla:</label>
                                <select
                                  value={ product.size_id || '' }
                                  onChange={ handleSelectSize }
                                  onClick={ (e) => e.stopPropagation() }
                                >
                                  <option value="">Seleccione</option>
                                  {
                                    prodSizes.map((_item) => {
                                      const size = sizes.find(i => i.id == _item);

                                      return (
                                        <option
                                          key={`size-${product.id}${_item}${new Date().getMilliseconds()}`}
                                          value={ size?.id }
                                        >
                                          { size?.name }
                                        </option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>

                  <div>
                    {enoughStock ? (
                      <button className="add-product" onClick={() => addToCart(product)}>
                        { isSelected ? 'Añadido' : 'Añadir' }
                      </button>
                    ) : (
                      <div className="product-action inline-flex items-center">
                        <span className="text-sm text-red-600">Sin stock</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <p className="pt-4">{ product.description_store }</p>
            </div>

            <ProductCarousel
              products={interestingProducts}
              cart={cart}
              addToCart={addToCart}
              showPrices={showPrices}
              setShowPreview={setShowPreview}
              setShowProduct={setShowProduct}
              identifier="from-product-details"
              interval={2}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ProductCarousel = ({ identifier = '', products, cart, addToCart, showPrices, setShowProduct, interval }) => {
  const componentID = `carousel-${identifier}`;
  const cContRef = useRef();
  const hScrollRef = useRef();
  const bScrollLeftRef = useRef();
  const bScrollRightRef = useRef();

  const showCarouselButtons = () => {
    if (cContRef.current.offsetWidth > hScrollRef.current.offsetWidth)
      bScrollRightRef.current.classList.add('sm:visible');
  }

  const scrollHorizontally = (val) => {
    let scrollAmount = hScrollRef.current.offsetWidth - ( !!interval ? 0 : 50 );
    let maxScroll = -cContRef.current.offsetWidth + hScrollRef.current.offsetWidth + 8;
    const positionInPixels = String(cContRef.current.style?.marginLeft).match(/-?\d+/g);
    let currentScrollPosition = (!!positionInPixels ? +positionInPixels[0] : 0) + (val * scrollAmount);

    if (currentScrollPosition > 0) {
      currentScrollPosition = 0;
      bScrollLeftRef.current.classList.remove('sm:visible');
    } else {
      bScrollLeftRef.current.classList.add('sm:visible');
    }

    if (currentScrollPosition <= maxScroll) {
      currentScrollPosition = maxScroll;
      bScrollRightRef.current.classList.remove('sm:visible');
    } else {
      bScrollRightRef.current.classList.add('sm:visible');
    }

    cContRef.current.style.marginLeft = currentScrollPosition + 'px';
  }

  useEffect(() => {
    if (!!products.length) showCarouselButtons();
    setTimeout(() => {
      if (!interval) return;
      const parent = document.getElementById(componentID);
      const elements = parent.getElementsByClassName('product-wrapper');
      const parentWidth = parent?.offsetWidth;
      for (let i = 0; i < elements.length; i++) {
        const itemWidth = (!!interval && !!parentWidth) ? `${parentWidth / interval}px` : '100%';
        elements.item(i).style.width = itemWidth;
      }
    }, 0);
  }, [products]);

  if (!products.length) return <></>;

  return (
    <section id={componentID}>
      <h1 className="mx-2 font-medium mb-2">Podría interesarte</h1>
      <div className="flex items-center whitespace-nowrap overflow-x-scroll hide-scroll-bar mb-8" ref={hScrollRef}>
        <button
          onClick={() => scrollHorizontally(1)}
          className="carousel-button left-0 invisible"
          type="button"
          ref={bScrollLeftRef}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>

        <button
          onClick={() => scrollHorizontally(-1)}
          className="carousel-button right-0 invisible"
          type="button"
          ref={bScrollRightRef}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </button>

        <div className="show-grid horizontal-products" ref={cContRef}>
          {products.map((product,index) => {
            const isSelected = Boolean(cart.find(x => x.id === product.id));

            return (
              <div className="product-wrapper" key={`product-${product.id}`}>
                <Product
                  product={product}
                  isSelected={isSelected}
                  addToCart={() => addToCart(product,index)}
                  show_prices={showPrices}
                  showProduct={() => setShowProduct({ show: true, product: product, isSelected })}
                  showCategory={false}
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const Product = ({ product, isSelected, addToCart, show_prices, showPreview, showProduct, showCategory = true }) => {
  const colors = useSelector(state => state.colors);
  const prodColors = product.type == SELL_TYPES.SIZE_COLORS
    ? [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))] || []
    : [];
  const enoughStock = product.type === SELL_TYPES.SIZE_COLORS
    ? product.size_colors.some(x => x.stock > 0)
    : product.stock > 0;

  const handleAddToCart = (e) => {
    e.stopPropagation();
    addToCart();
  }

  const Price = () => {
    if (!show_prices) return <></>;

    let precio = 0
    if(Number(product.type) === SELL_TYPES.NORMAL){
      if(product.default_price == 1){
        precio = product.sale_price
      }
      else{
        // Busca el primer objeto en product.prices donde default_price es 1
        const priceObj = product.prices.find(price => price.default_price === 1);
        // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
        if (priceObj) {
          precio = priceObj.price;
        }
      }
    }
    else{
      precio = product.minPrice
    }

    return (
      <h3 className="product-price font-medium leading-none">
        {/* {Number(product.type) === SELL_TYPES.NORMAL
          ? formatAmount(product.sale_price)
          : formatAmount(product.minPrice)
        } */}
        { formatAmount(precio) }
      </h3>
    )
  }

  return (
    <div
      className="product-item"
      onClick={() => !!showProduct ? showProduct() : {}}
    >
      <div className="flex sm:items-center">
        <div className="product-image">
          <img
            className={'object-cover text-xs ' + (enoughStock ? 'opacity-100' : 'opacity-25')}
            src={process.env.REACT_APP_STORAGE + product.photo?.file}
            alt={product.name}
            onClick={() => !showProduct && !!showPreview ? showPreview() : {}}
          />
        </div>
      </div>

      <div className="product-bottom">
        <div className="flex flex-1 flex-col gap-2">
          {enoughStock ? (
            <div>
              {product.type == SELL_TYPES.NORMAL ? (
                isSelected
                  ? <ButtonAdded onClick={handleAddToCart} />
                  : <ButtonAdd onClick={handleAddToCart} />
              ) : (
                <ButtonAdd multiple />
              )}
            </div>
          ) : (
            <div className="product-action inline-flex items-center">
              <span className="text-sm text-red-600">Sin stock</span>
            </div>
          )}

          <div className="flex flex-row flex-wrap justify-between">
            <Price />

            {(prodColors.length > 0) && (
              <div className="color-preview">
                {prodColors.map((i) => {
                  const hexColor = colors.find(item => item.id == i)?.hex || '#FFFFFF';
                  return (
                    <div
                      key={`color-${product.id}${i}${new Date().getMilliseconds()}`}
                      className="color-circle"
                      style={{ backgroundColor: hexColor, borderWidth: 0 }}
                    />
                  )
                })}
              </div>
            )}
          </div>

          <div className="product-name">
            <span onClick={() => !!showProduct ? showProduct() : {}}>
              {product.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

const ButtonAdd = ({ onClick = () => {}, multiple }) => (
  <button onClick={onClick} className="button-add">
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12H18M12 6V18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    <span className="text-xs md:text-sm font-semibold">{ multiple ? 'Opciones' : 'Añadir' }</span>
  </button>
)

const ButtonAdded = ({ onClick }) => (
  <button onClick={onClick} className="button-added">
    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 12H18M12 6V18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    <span className="text-xs md:text-sm font-semibold">Añadido</span>
  </button>
)

const SidebarOrder = ({ show, selected, onSelect, onClose }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={() => onClose()}>
      <div className="absolute inset-0 overflow-hidden">
        <Dialog.Overlay className="absolute inset-0" />

        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="pointer-events-auto w-screen max-w-md">
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900"> Ordenar por </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => onClose()}
                        >
                          <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 mx-2">
                      {SORT_OPTIONS.map(x => (
                        <li
                          key={`modal-sort-${x.value}`}
                          onClick={() => {
                            onSelect(x.value);
                            onClose();
                          }}
                        >
                          <div className={
                            'flex items-center cursor-pointer p-2 text-base font-normal text-gray-500 rounded-lg hover:bg-gray-100' +
                            (selected === x.value ? ' bg-gray-200' : '')
                          }>
                            <span className="ml-3">{x.label}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                    onClick={() => onClose()}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
)

const CartResume = ({ cart, onClick, show_prices }) => {
  let total = 0;
  for (const product of cart) {
    let sale_price = 0;
    if(Number(product.type) === SELL_TYPES.NORMAL){
      if(product.default_price == 1){
        sale_price = product.sale_price
      }
      else{
        // Busca el primer objeto en product.prices donde default_price es 1
        const priceObj = product.prices.find(price => price.default_price === 1);
        // Si se encuentra un objeto que cumple con la condición, asigna su price a sale_price
        if (priceObj) {
          sale_price = priceObj.price;
        }
      }
    }
    else if (product.type == SELL_TYPES.SIZE_COLORS) {
      const size_color = product?.size_colors?.find((i) => {
        if (product.size_id && product.color_id) {
          return i.size_id == product.size_id && i.color_id == product.color_id;
        }
        else if (product.size_id) {
          return i.size_id == product.size_id && i.color_id == null;
        }
        else if (product.color_id) {
          return i.color_id == product.color_id && i.size_id == null;
        }
      });

      const priceObj = size_color?.prices.find(price => price.default_price === 1);
      // Si se encuentra un objeto que cumple con la condición, asigna su price a precio
      if (priceObj) {
        sale_price = priceObj.price;
      }
      else {
        sale_price = size_color?.sale_price
      }
    }
    total = toAmount(total + sale_price * product.quantity);
  }

  return (
    <div onClick={() => onClick()} className="cart-resume">
      <div className="flex flex-col justify-between items-center">
        {!!cart.length && (
          <span className="inline-flex justify-center items-center w-5 h-5 absolute bottom-12 ml-2 z-[1] text-xs font-medium bg-yellow-400 rounded-full">
            { cart.length }
          </span>
        )}
        <img
          style={{ ...getFinalImageColor('--bg-header','#000000') }}
          src={ShoppingCartIcon}
          alt="Carrito de compras"
          title="Carrito de compras"
        />
      </div>
      {(show_prices && !!cart.length) && (
        <span className="text-xs md:text-sm text-center text-[--fg-header] absolute top-[4rem] md:top-[4.5rem]">
          { formatAmount(total) }
        </span>
      )}
    </div>
  );
}

export default SearchProducts;