import { store } from '../store';

export const toParams = (obj) => {
  let str = '';
  for (let key in obj) {
    if (str !== '') str += '&';
    if (typeof obj[key] === 'boolean') obj[key] = Number(obj[key]);
    str += key + '=' + obj[key];
  }
  return '?' + str;
}

export const toAmount = (x) => parseFloat(Number(x).toFixed(2));

const fillDecimal = (x) => String(x).replace(/((\.|,)\d)$/, (_, p1) => p1 + '0');

export const formatAmount = (x) => {
  const { currency_format, currency_symbol } = store.getState().currency;
  const conversion = store.getState().conversion;
  const amount = toAmount(Number(x) * conversion);
  const formatted = Intl.NumberFormat(currency_format || 'es-US', { maximumFractionDigits: 2 }).format(amount);
  return `${currency_symbol || ''} ${fillDecimal(formatted)}`;
}

const rifValidPreffixes = ['V','J','E','P','G'];
const rifValidItems = [...rifValidPreffixes,'0','1','2','3','4','5','6','7','8','9'];

export const isValidRifPreffix = (value) => {
  return rifValidPreffixes.some(letter => String(value).toLowerCase().startsWith(letter.toLowerCase()));
}

export const onlyRifChars = (e) => {
  if (e.code === 'Backspace') return;
  if (!rifValidItems.some(letter => [letter, letter.toLowerCase()].includes(e.key)))
    e.preventDefault();
}

export const copy = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const invertTextColor = (backgroundColor) => {
  // Convierte el color de fondo a un valor decimal
  const hexToDecimal = (color) => parseInt(color, 16);

  // Obtiene los componentes RGB del color de fondo
  const red = hexToDecimal(backgroundColor.substr(1, 2));
  const green = hexToDecimal(backgroundColor.substr(3, 2));
  const blue = hexToDecimal(backgroundColor.substr(5, 2));

  // Calcula el brillo relativo del color de fondo
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  // Determina si el color de fondo es claro o oscuro
  return brightness > 125 ? '#000000' : '#FFFFFF';
}

export const mustInvertImageColor = (bgColor, imgColor) => {
  return Number(invertTextColor(bgColor) !== imgColor);
}

export const getFinalImageColor = (variable, realColor) => {
  const bgColor = document.documentElement.style.getPropertyValue(variable);
  return { filter: `invert(${mustInvertImageColor(bgColor, realColor)})` };
}

export const setGlobalColors = (headerBg, footerBg) => {
  const BG_HEADER = headerBg || '#C70FB1';
  const FG_HEADER = invertTextColor(BG_HEADER);
  const BG_FOOTER = footerBg || '#333333';
  const FG_FOOTER = invertTextColor(BG_FOOTER);
  document.documentElement.style.setProperty('--bg-header', BG_HEADER);
  document.documentElement.style.setProperty('--fg-header', FG_HEADER);
  document.documentElement.style.setProperty('--bg-header-border', FG_HEADER + '88');
  document.documentElement.style.setProperty('--bg-footer', BG_FOOTER);
  document.documentElement.style.setProperty('--fg-footer', FG_FOOTER);
}