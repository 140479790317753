const initialState = 1;

const conversion = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_CONVERSION':
      return payload;

    default:
      return state;
  }
}

export default conversion;