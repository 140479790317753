const initialState = [];

const SELL_TYPES = {
  NORMAL: 1,
  SIZE_COLORS: 2
}

const cart = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'FILL_CART':
      return payload;

    case 'ADD_TO_CART':
      return [ ...state, payload ];

    case 'REMOVE_FROM_CART':
      return state.filter(x => x.id !== payload.id);

    case 'UPDATE_CART_PRODUCT':
      const idx = state.findIndex(x => {
        return (payload.type == SELL_TYPES.NORMAL)
          ? x.id === payload.id
          : x.id === payload.id && x.size_id == payload.size_id && x.color_id == payload.color_id;
      });
      const all = [ ...state ];

      all.splice(idx, 1, payload);

      return all;

    case 'CLEAR_CART':
      return [];

    default:
      return state;
  }
}

export default cart;