const SocketEvents = {
	CATALOGUE: {
		UPDATE: 'catalogue/update'
	},
	NEW_VISIT_PLANNER: 'new-visit-planner',
	UPDATE_SUPERADMIN: 'superadmin/update',
	PUBLIC_STORE: {
		PREVIEW: {
			BANNER: 'public-store/preview/banner',
			COLORS: 'public-store/preview/colors',
		}
	}
}

export default SocketEvents;